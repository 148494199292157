/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

window.$ = window.jQuery = require('jquery');
require('jquery-ui');
require('jquery-validation');

var countries = require('./countries'),
    dialog = require('./dialog'),
    minicart = require('./minicart'),
    page = require('./page'),
    rating = require('./rating'),
    searchsuggest = require('./searchsuggest'),
    tooltip = require('./tooltip'),
    util = require('./util'),
    validator = require('./validator'),
    tls = require('./tls'),
    search = require('./search'),
    accordion = require('./accordion'),
    drawer = require('./drawer'),
    contentDialog = require('./content-dialog'),
    smoothScroll = require('./smooth-scroll'),
    slider = require('./slider');

require('./jquery-ext')();
require('./cookieprivacy')();
require('./captcha')();
require('./firearmsquestion')();

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
            '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="' + status + '">' + data.msg + '</div>');

    $('.email-signup-message').insertAfter('.c-email-subscribe-form');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 5000);
}

function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });
    
    $('body').on('keydown', '.mini-cart-content div', function (e) {
    	if (e.which == 27) {
    	    minicart.close();
    	}
    });
    $('.c-global-navigation__link').on('keydown', function (e) {
        if (e.which == 13) {
            var $aria = $(this).attr('aria-expanded');
            if ($aria === 'true') {
                $(this).attr('aria-expanded', 'true');
            } else  {
                $(this).attr('aria-expanded', 'false');
            }
        }
    });
    $('body').on('click', function (e) {
        var currentSubmenu = $('body').find('.c-dropdown');
        currentSubmenu.removeAttr("style");
        if (e.which == 27) {
            if ($(this).attr("aria-expanded") == "false"){
                $(this).attr("aria-expanded","true");
            } else {
                $(this).attr("aria-expanded","false");
            }
        }
    });

    $('body').on('keydown', '.c-dropdown a.c-dropdown__link', function (e) {
        if (e.which == 13 || e.which == 40 ) {
            window.location.href=e.target;
        }
    });

    $('body').on('keydown', '.c-global-navigation__item', function (e) {
        if (e.which == 13 || e.which == 40 || e.which == 32) {
            e.preventDefault();
            $('.c-global-navigation__item').removeClass('topLevelReturn');
            $(this).addClass('topLevelReturn');
            var currentSubmenu = $(this).find('.c-dropdown');
            var firstSubmenuLink = $(this).find('.c-dropdown__link:first');
            var lastSubmenuLink = $(this).find('.c-dropdown__link:last');
            firstSubmenuLink.addClass('firstItem');
            lastSubmenuLink.addClass('lastItem');
            currentSubmenu.css('visibility','visible');
            currentSubmenu.css('opacity','1');
            firstSubmenuLink.focus();
        }
        if(e.which == 27) {
            var currentSubmenu = $(this).find('.c-dropdown');
            currentSubmenu.removeAttr("style");
        }
    });

    $(window).load(function() {
        if (sessionStorage.getItem('value') !== null) {
            var value = sessionStorage.getItem('value');
            var $focus= $("a:contains('" + value + "')");
            $focus.focus();
            setTimeout(function () {
                sessionStorage.clear();
            }, 5000);  
        }
                
    });
    
    $('body').on('keydown', '.slick-next', function(e) {    
        setTimeout(function(){
            if (e.which==13) {
                $('body').find('.slick-active').find('.c-primary-slider__item').focus();
            }

            if (e.which==9) {
                $('.play-pause-container').css('opacity', '0');
            }

            $('body').on('keydown', '.c-global-navigation__item', function (e) {
                if (e.which == 13 || e.which == 40) {
                    e.preventDefault();
                    $('.c-global-navigation__item').removeClass('topLevelReturn');
                    var $aria = $(this).attr('aria-expanded');
                    if ($aria === 'true') {
                        $(this).attr('aria-expanded', 'true');
                    } else  {
                        $(this).attr('aria-expanded', 'true');
                    }
                    $(this).addClass('topLevelReturn');
                    var currentSubmenu = $(this).find('.c-dropdown');
                    var firstSubmenuLink = $(this).find('.c-dropdown__link:first');
                    var lastSubmenuLink = $(this).find('.c-dropdown__link:last');
                    firstSubmenuLink.addClass('firstItem');
                    lastSubmenuLink.addClass('lastItem');
                    currentSubmenu.css('visibility','visible');
                    currentSubmenu.css('opacity','1');
                    firstSubmenuLink.focus();
                }
            });
    
            $('body').on('keydown', '.firstItem', function (e) {
                if (e.which == 38 || (e.shiftKey && e.which == 9)) {
                    var currentSubmenu = $(this).closest('.c-globaln-navigation');
                    var lastSubmenuLink = currentSubmenu.find('.c-dropdown__link:last');
                    $(this).removeClass('firstItem');
                    currentSubmenu.css('visibility','');
                    currentSubmenu.css('opacity','');
                    lastSubmenuLink.removeClass('lastItem');
                    currentSubmenu.focus();
                }
            });
    
            $('body').on('keydown', '.lastItem', function (e) {
                if (e.which == 9 || e.which == 40) {
                    var currentSubmenu = $(this).closest('.c-dropdown');
                    var lastSubmenuLink = currentSubmenu.find('.c-dropdown__link:last');
                    $(this).removeClass('firstItem');
                    currentSubmenu.css('visibility','');
                    currentSubmenu.css('opacity','');
                    lastSubmenuLink.removeClass('lastItem');
                    if (!currentSubmenu.hasClass('last')) {
                        currentSubmenu.focus();
                    }
                }
            });
        }, 500);
    });
    $('#skip-to-main').on('keydown', function(e) {
        if (e.which === 9) {
            e.preventDefault();
            setTimeout(function(){
                $('#leftNav').focus();
            }, 500);
        }
    }); 
    

    $('body').on('keydown', '.slick-prev', function(e) { 
        $('.play-pause-container').css('opacity', '1');
        setTimeout(function(){
            if (e.which==13) {
                $('body').find('.slick-active').find('.c-primary-slider__item').focus();
            }
        }, 500);
    });   

    $('body').on('keydown', '.c-global-navigation__item', function (e) {
        if (e.which == 13 || e.which == 40) {
            e.preventDefault();
            $('.c-global-navigation__item').removeClass('topLevelReturn');
            $(this).addClass('topLevelReturn');
            var currentSubmenu = $(this).find('.c-dropdown');
            var firstSubmenuLink = $(this).find('.c-dropdown__link:first');
            var lastSubmenuLink = $(this).find('.c-dropdown__link:last');
            firstSubmenuLink.addClass('firstItem');
            lastSubmenuLink.addClass('lastItem');
            currentSubmenu.css('visibility','visible');
            currentSubmenu.css('opacity','1');
            firstSubmenuLink.focus();
        }
    });

    $('body').on('keydown', '.mini-cart-link-checkout--last', function (e) {
        if (e.which == 9) {
            $('body').find('.mini-cart-header').focus();
        }
    
    });
    
    $('.c-dropdown__link, .c-dropdown__level, .c-dropdown__item-wraper').on('focus', function (e) {
        var currentSubmenu = $(this).closest('.c-dropdown');
        currentSubmenu.addClass('opened');
        currentSubmenu.css('visibility','visible');
        currentSubmenu.css('opacity','1');
    });
    
    $('.lastItem').on('blur', function (e) {
        var currentSubmenu = $(this).closest('.c-dropdown');
        currentSubmenu.css('visibility','none');
        currentSubmenu.css('opacity','0');
        currentSubmenu.removeClass('opened');
    });
    
    $('.c-feature-block__link').on('blur', function (e) {
        var currentSubmenu = $(this).closest('.c-dropdown');
        currentSubmenu.css('visibility','none');
        currentSubmenu.css('opacity','0');
        currentSubmenu.removeClass('opened');
    });

    if ($('.clothing-max-height').next('.c-dropdown__feature').find('.c-feature-block').length > 0) {
        $('.clothing-max-height').parent().addClass('max-width');
    }

    if ($('.footwear-max-height').next('.c-dropdown__feature').find('.c-feature-block').length > 0) {
        $('.footwear-max-height').parent().addClass('max-width');
    }

    $('body').on('keydown', '.firstItem', function (e) {
        if (e.which == 38 || (e.shiftKey && e.which == 9)) {
            var currentSubmenu = $(this).closest('.c-dropdown');
            var lastSubmenuLink = currentSubmenu.find('.c-dropdown__link:last');
            $(this).removeClass('firstItem');
            currentSubmenu.css('visibility','');
            currentSubmenu.css('opacity','');
            lastSubmenuLink.removeClass('lastItem');
            currentSubmenu.focus();
        }
    });
    
    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $('.js-search-form');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

    // add show/hide navigation elements
    $('.secondary-navigation .toggle').click(function () {
        $(this).toggleClass('expanded').next('ul').toggle();
    });

    // add generic toggle functionality
    $('.toggle').next('.toggle-content').hide();

    $('.toggle').click(function () {
        $(this).toggleClass('expanded').next('.toggle-content').toggle();
    });

    $('body').on('click', '.js-refinement-toggle', function(ev) {
        var $toggle = $(ev.target); 
        $toggle.children('i').toggleClass('fa-angle-down').toggleClass('fa-angle-up');
    });

    $('body').on('click', '.js-refinement-toggle i', function(ev) {
        var $toggle = $(ev.target); 
        $toggle.toggleClass('fa-angle-down').toggleClass('fa-angle-up');
    });
    
    var browserZoom = Math.round(window.devicePixelRatio * 100);

    if (browserZoom >= 400) {
        $('.tableHolder').css({"font-size": "10px", "overflow": "auto"});
    } else {
        $('.tableHolder').css('overflow', 'none');
    }

    $('body').on('keydown', '.refinement-section-header', function(e) {
        if (e.which == 13) {
            var $aria = $(this).attr("aria-expanded");
            if ($aria === 'true'){
                $(this).attr("aria-expanded","false");
            } else {
                $(this).attr("aria-expanded","true");
            }
        }
    
    });

    $('body').on('keydown', '.st-global-header__mobile-icon-menu', function(e) {
        if (e.which==13) {
            $(this).attr("aria-expanded", "true");
        }
    });

    $('body').on('keydown', '.c-global-navigation__item', function(e) {
        if (e.which==13 || e.which==32 || e.which == 27) {
            if ($(this).attr("aria-expanded") == "false"){
                $(this).attr("aria-expanded","true");
            } else {
                $(this).attr("aria-expanded","false");
            }
        }
    });

    $(window).on('resize', function () {
        if (browserZoom >= 400) {
            $('.tableHolder').css({"font-size": "10px", "overflow": "auto"});
        } else {
            $('.tableHolder').css('overflow', 'none');
        }
    });

    $('body').on('keydown', '.refinement-section-header', function(e) {
        if (e.which == 13) {
            if ($(this).attr("aria-expanded") == "false"){
                $(this).attr("aria-expanded","true");
            } else {
                $(this).attr("aria-expanded","false");
            }
        }
    
    });

    $('.menu-ea__mobile').on('keypress ontouchend', '.menu-ea__mobile', function(e) {
        if (e.key == 9){
            e.preventDefault();
            var $menu = $('.c-icon-menu-close');
            $menu.focus();
        }
    });


    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0)    {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }
    
    $('.c-email-subscribe-form__submit').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=dwfrm_emailsubscribeform_email]').val();
        if (!emailId || emailId == '') return;
        var isFooter = $(this).parents('#footercontent');
        $(this).attr('disabled', true);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                source: 'Footer'
            },
            success: function (data) {
                displayMessage(data, button);
                // clearing input field
                $('input[name=dwfrm_emailsubscribeform_email]').val('');
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });

    $('.privacy-policy').on('click', function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr('href'),
            options: {
                height: 600
            }
        });
    });

	// if no price checkbox is selected, set tabindex of first item to 0
	if($('.refinement.price li.selected').length === 0) {
		$('.refinement.price ul li .refinement-checkbox').first().attr('tabindex', '0');
	}

    $('.refinement.price .refinement-checkbox').on('keydown', function(e) {

		// enter, space
        if (e.which == 13 || e.which == 32) {
            e.preventDefault();
            var $value = $(this).text();    
            sessionStorage.setItem('value', $value);
            window.location.href=e.target;
            $(this).attr('aria-live', 'polite');
            $(this).focus();
        }

		// left, up
		else if(e.which == 37 || e.which == 38) {
			e.preventDefault();

			var options = $('.refinement.price ul li');
			var current = $(e.target);
			var parent = $(current).parent();

			// reset tabindex
			$(options).find('.refinement-checkbox').attr('tabindex', '-1');

			// currently on first option, wrap to last
			if($(options).first().is(current.parent())) {
				$(options).last().find('.refinement-checkbox').focus().attr('tabindex', '0');
			}

			else {
				$(parent).prev().find('.refinement-checkbox').focus().attr('tabindex', '0');
			}
		}

		// right, down
		else if(e.which == 39 || e.which == 40) {
			e.preventDefault();

			var options = $('.refinement.price ul li');
			var current = $(e.target);
			var parent = $(current).parent();

			// reset tabindex
			$(options).find('.refinement-checkbox').attr('tabindex', '-1');

			// currently on last option, wrap to first
			if($(options).last().is(current.parent())) {
				$(options).first().find('.refinement-checkbox').focus().attr('tabindex', '0');
			}

			else {
				$(parent).next().find('.refinement-checkbox').focus().attr('tabindex', '0');
			}
		}

		// tab
		else if(e.which == 9) {
			e.preventDefault();

			// previous focusable item is price header
			if(e.shiftKey) {
				$('.refinement.price h3').focus();
			}

			else {

				// next item in focus is clear button if available
				if($('.refinement.price .clear-refinement a').length > 0) {
					$('.refinement.price .clear-refinement a').focus();
				}

				else {
					$('.refinement.rawSize ul li a').first().focus();
				}
			}
		}
    });

    $('.refinement-checkbox').on('click', function(e) {
            e.preventDefault();
            var $value = $(this).text();    
            sessionStorage.setItem('value', $value);
            window.location.href=e.target;
            $(this).attr('aria-live', 'polite');
            $(this).focus();
        
    });

    $('.clear-refinement').on('click', function(e) {
        e.preventDefault();
        window.location.href=e.target;
    })

    // main menu toggle
    $('.menu-toggle').on('click', function () {
        $('#wrapper').toggleClass('menu-active');
    });
    $('.menu-category li .menu-item-toggle').on('click', function (e) {
        e.preventDefault();
        var $parentLi = $(e.target).closest('li');
        $parentLi.siblings('li').removeClass('active').find('.menu-item-toggle').removeClass('fa-chevron-up active').addClass('fa-chevron-right');
        $parentLi.toggleClass('active');
        $(e.target).toggleClass('fa-chevron-right fa-chevron-up active');
    });
    $('.user-account').on('click', function (e) {
        e.preventDefault();
        $(this).parent('.user-info').toggleClass('active');
    });
}
/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();
}

var pages = {
    account: require('./pages/account'),
    cart: require('./pages/cart'),
    checkout: require('./pages/checkout'),
    compare: require('./pages/compare'),
    product: require('./pages/product'),
    registry: require('./pages/registry'),
    search: require('./pages/search'),
    wishlist: require('./pages/wishlist'),
    storelocator: require('./pages/storelocator'),
    content: require('./pages/content')
};

var app = {
    init: function () {
        if (document.cookie.length === 0) {
            $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('browser-error').html(Resources.COOKIES_DISABLED)).appendTo('#browser-check');
        }
        initializeDom();
        initializeEvents();

        var GTM = require('../../../int_google/cartridge/js/GTM');
        GTM.init();

        // init specific global components
        countries.init();
        tooltip.init();
        search.init();
        minicart.init();
        validator.init();
        rating.init();
        accordion.init();
        drawer.init();
        contentDialog.init();
        smoothScroll.init();
        slider.init();

        window.sgDialog = require('./dialog');
        window.sgUtil = require('./util');

        // execute page specific initializations
        $.extend(page, window.pageContext);
        var ns = page.ns;
        if (ns && pages[ns] && pages[ns].init) {
            pages[ns].init();
        }

        // Check TLS status if indicated by site preference
        if (SitePreferences.CHECK_TLS === true) {
            tls.getUserAgent();
        }

        $('#dwfrm_emailsignup_selectall').on('click', function (e) {
            var checkboxes = document.getElementsByTagName('input');
            if (this.checked) {
                for (var i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].type == 'checkbox') {
                        checkboxes[i].checked = true;
                    }
                }
            } else {
                for (var i = 0; i < checkboxes.length; i++) {
                    console.log(i)
                    if (checkboxes[i].type == 'checkbox') {
                        checkboxes[i].checked = false;
                    }
                }
            }
        });

    }
};

// general extension functions
(function () {
    String.format = function () {
        var s = arguments[0];
        var i, len = arguments.length - 1;
        for (i = 0; i < len; i++) {
            var reg = new RegExp('\\{' + i + '\\}', 'gm');
            s = s.replace(reg, arguments[i + 1]);
        }
        return s;
    };
})();

// initialize app
$(document).ready(function () {
    app.init();
});
